import React, { useState } from 'react';
import { FaEnvelope, FaPhoneAlt } from 'react-icons/fa';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeModal = () => {
    setMenuOpen(false);
  };

  return (
    <div className="bg-blue-100">
      {/* Top section with the button */}
      <div className="bg-blue-600 text-white text-center py-2">
        <button className="inline-block px-8 py-2 rounded-full text-sm">
          Join Our Personalized Fitness Demo For Free
        </button>
      </div>

      {/* Bottom section with logo and contact details */}
      <div className="container mx-auto flex justify-between items-center py-4 px-6 md:px-12">
        {/* Logo on the left */}
        <div className="flex items-center">
          <img src="/path-to-logo.png" alt="Stadium Logo" className="h-12" />
        </div>

        {/* Hamburger Icon on the right */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-blue-600 text-sm">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>

        {/* Contact details for desktop view */}
        <div className="hidden md:flex items-center space-x-6">
          <button className="text-blue-800 text-sm flex items-center">
            <FaEnvelope className="h-5 w-5 mr-2" />
            stadium.fitness@outlook.com
          </button>
          <button className="text-blue-800 text-sm flex items-center">
            <FaPhoneAlt className="h-5 w-5 mr-2" />
            +63 916 732 6946
          </button>
        </div>
      </div>

      {/* Modal for mobile view */}
      {menuOpen && (
        <div className="fixed inset-0 z-50">
          {/* Background overlay */}
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={closeModal}
          ></div>

          {/* Modal content */}
          <div className="fixed inset-y-0 right-0 w-3/4 bg-[#f0f3fe] p-6 shadow-lg transform transition-transform duration-300 ease-in-out z-50">
            <button
              className="absolute top-0 right-0 m-4 text-gray-700"
              onClick={closeModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h3 className="text-lg font-bold mb-4">Contact Us</h3>
            <button
              className="block text-blue-800 text-sm mb-2 flex items-center"
            >
              <FaEnvelope className="h-5 w-5 mr-2" />
              stadium.fitness@outlook.com
            </button>
            <button
              className="block text-blue-800 text-sm flex items-center"
            >
              <FaPhoneAlt className="h-5 w-5 mr-2" />
              +63 916 732 6946
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
