import React from 'react';

const HeroSection = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-between py-12 w-full bg-[#f0f3fe]">
      {/* App Preview Image */}
      <div className="w-full md:w-1/2 flex justify-center md:justify-end mb-8 md:mb-0 order-1 md:order-2">
        <img src="/path-to-app-preview.png" alt="App Preview" className="w-full max-w-lg md:max-w-3xl lg:max-w-5xl" />
      </div>

      {/* Text and Buttons Section */}
      <div className="w-full md:w-1/2 px-6 text-center md:text-left order-2 md:order-1">
        <h1 className="text-base md:text-lg font-normal mb-1 leading-snug underline decoration-[#354ad9] font-montserrat">
          Transform Your Fitness journey with
        </h1>
        <h2 className="text-2xl md:text-4xl font-extrabold mb-4 leading-snug text-[#000] font-bebasnue">
          Stadium Fitness App
        </h2>
        <p className="text-[#000] text-sm md:text-base mb-4 leading-relaxed font-montserrat">
          Stadium Fitness is the revolutionary app that simplifies your fitness journey. By focusing on essential metrics and providing a supportive community, we empower you to achieve your health and fitness goals with ease. Whether you're a beginner or a seasoned athlete, Stadium Fitness adapts to your needs, making every step of your fitness journey count.
        </p>

        {/* Buttons Row */}
        <div className="flex items-center justify-center md:justify-start gap-4 mb-6 w-full">
          <button className="bg-[#354ad9] text-white px-4 py-2 md:px-6 md:py-3 rounded-lg shadow-md w-auto text-xs md:text-sm font-bebasnue">
            Join the Waitlist
          </button>
          <button className="bg-gray-300 text-gray-700 px-4 py-2 md:px-6 md:py-3 rounded-lg shadow-md w-auto text-xs md:text-sm font-bebasnue">
            Book a Demo
          </button>
        </div>

        {/* Customer Avatars and Happy Customers Text */}
        <div className="flex flex-col md:flex-row items-center justify-center md:justify-start mb-6">
          <div className="flex -space-x-2 justify-center">
            <img src="/path-to-customer1.jpg" alt="Customer 1" className="w-8 h-8 md:w-10 md:h-10 rounded-full border-2 border-white" />
            <img src="/path-to-customer2.jpg" alt="Customer 2" className="w-8 h-8 md:w-10 md:h-10 rounded-full border-2 border-white" />
            <img src="/path-to-customer3.jpg" alt="Customer 3" className="w-8 h-8 md:w-10 md:h-10 rounded-full border-2 border-white" />
          </div>
          <p className="mt-4 md:mt-0 md:ml-4 text-gray-600 text-xs md:text-base font-montserrat">430+ <span className="text-[#354ad9]">Happy Customers</span></p>
        </div>

        {/* App Store and Google Play Buttons */}
        <div className="flex items-center justify-center md:justify-start gap-4">
          <button className="w-1/2 md:w-auto">
            <img src="/path-to-appstore-badge.png" alt="App Store" className="w-full h-auto" />
          </button>
          <button className="w-1/2 md:w-auto">
            <img src="/path-to-playstore-badge.png" alt="Google Play" className="w-full h-auto" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
