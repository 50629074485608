import React from 'react';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import Footer from '../components/Footer';

const LandingPage = () => {
  return (
    <div className="bg-gray-100 flex flex-col min-h-screen">
      <Header />
      <div className="container mx-auto px-4 md:px-6 lg:px-8 flex-grow">
        <HeroSection />
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
