import React from 'react';

const Footer = () => {
  return (
    <div className="bg-black py-6 mt-8">
      <div className="container mx-auto flex justify-center">
        <div className="bg-blue-600 text-white text-center py-2 px-6 rounded-full text-sm">
          © 2024, Stadium. All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
